body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

.button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  outline: none;
  //border-radius: 3px;
  &.primary {
    background: #fae346;
    color: #000000;
  }
  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
    &:disabled {
      transform: scale(1);
    }
  }
}

.help-modal {
  li {
    line-height: 2em;
  }
}

.upload-modal {
  .or {
    text-align: center;
    margin: 20px;
  }
  .samples {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    img {
      height: 20px;
      margin-left: 5px;
    }
  }
  .dropzone {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: solid 1px;

    .title {
      margin: 20px;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .format {
      margin: 20px;
      .sub-title {
        text-decoration: underline;
        margin-bottom: 20px;
      }
      text-align: center;
    }
  }
}

.editor-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .menu {
    color: #fae346;
    flex: 0;
    display: flex;
    flex-direction: row;
    background: #000000;
    align-items: center;
    padding: 7px 10px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 30px;
        margin-right: 5px;
      }
    }
    .right {
      flex: 0;
      display: flex;
      flex-direction: row;
    }

    button {
      margin: 0px 4px;
    }
  }

  .editor-wrapper {
    position: relative;
    flex: 1;
    overflow: hidden;

    .viewport {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      canvas {
      }
    }

    .properties-panel {
      color: #fae346;
      display: none;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 12px;
      width: 200px;
      padding: 10px;
      background: rgba(0, 0, 0, 0.8);
      overflow: auto;
      z-index: 1;
      &.show {
        display: flex;
      }

      .section {
        width: 100%;
        .sub-title {
          padding: 2px;
        }
        .row {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;
          input.col {
            flex: 1 1;
            width: 20px;
            margin: 0 2px;
            color: #fae346;
            background: grey;
          }
        }
      }
    }

    .assets-panel {
      color: #fae346;
      position: absolute;
      left: 10px;
      top: 10px;
      background: rgba(0, 0, 0, 0.8);
      width: 120px;

      .list {
        .item {
          padding: 10px;
          cursor: pointer;
          &:hover,&.selected {
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .add-asset {
        padding: 10px;
        button {
          width: 100%;
        }
      }
    }

    .transform-panel {
      display: none;
      position: absolute;
      right: 10px;
      bottom: 10px;
      flex-direction: row;
      z-index: 1;

      &.show {
        display: flex;
      }

      .buttons {
        display: flex;
        flex-direction: row;

        .button {
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          border: solid 1px #3f4652;
          border-radius: 0;
          outline: none;
          img {
            width: 30px;
          }
          &:hover,&.selected {
            background: rgba(0, 0, 0, 0.8);
            color: white;
          }
        }
      }
    }
  }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 999;

  &.show {
    display: flex;
    align-items: center;
  }

  .modal-mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .modal-inner {
    position: absolute;
    left: 10%;
    width: 80%;
    //min-height: 500px;

    //top: 20%;
    //height: 60%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;

    .modal-header {
      display: flex;
      height: 40px;
      align-items: center;
      flex-direction: row;
      //background: #6699b2;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 5px 20px;
      border-radius: 5px 5px 0 0;

      .title {
        flex: 1;
        font-weight: bold;
      }

      .close {
        color: white;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    .modal-content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      background-color: #fefefe;
      border: 1px solid #888;
    }
  }

  &.small {
    .modal-inner {
      left: 25%;
      width: 50%;
    }
  }
}

.footer {
  color: white;
  background-color: #000000;
  padding: 10px;
  display: flex;
  flex-direction: row;

  .left {
    flex: 1;
    display: flex;
    .help {
      color: #fae346;
      svg {
        margin-right: 4px;
      }
      cursor: pointer;
    }
  }
  .right {
    text-align: right;
    a {
      color: #fae346;
      text-decoration: none;
    }
  }
}
